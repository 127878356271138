.gradient-button {
  margin-top: 30px;
  width: 400px;
  height: 40px;
  font-weight: 600;
  font-size: 14px;
  background-color: transparent;
  color: #110B03;
  border-image: linear-gradient(to right, #D1A125, #E47C31, #388DDB) 1;
  border-style: solid;
  border-width: 2px;
  border-radius: 30px;
}
