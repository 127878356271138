
.notifications {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  
  .notifications h1 {
    margin-bottom: 10px;
    font-size: 28px;
    font-weight: bold;
  }
  
  .received-invites, .sent-invites {
    width: 80%;
    margin-bottom: 20px;
  }

  .beepboop {
    display:flex;
    align-items:center;
    justify-content:flex-start;
  }
  
  .received-invites h3, .sent-invites h3 {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
  }
  
  .received-invites-buttons, .sent-invites-buttons {
    display: flex;
    justify-content: end;
    margin-bottom: 20px;
    width:380px;

  }

  .transparent-btn {
    font-weight:600;
    background-color: transparent;
    border-color:#fcedc9;
    border-radius: 12px;
    border-style: solid;
    width:120px;
    height:30px;
    margin-left:10px;
    cursor: pointer;
  }
  
 .card {
    display: flex;
    align-items: center;
    padding: 28px;
    background-color: #fcedc9;
    margin-bottom: 10px;
  }
  
  .received-invites .card img, .sent-invites .card img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 20px;
  }
  
  .received-invites .card-buttons, .sent-invites .card-buttons {
    display: flex;
    justify-content: space-between;
    
  }
  
  .received-invites .card button {
    padding: 0px;
    margin-left:20px;
    border: none;
    border-radius: 12px;
    color: black;
    font-weight: bold;
    cursor: pointer;
    width:95px;
    height: 30px;
    margin-right: -10px;
  }

 .sent-invites .card button {
    padding: 0px;
    margin-left:20px;
    border: none;
    border-radius: 12px;
    color: black;
    font-weight: bold;
    cursor: pointer;
    width:115px;
    height: 30px;
    margin-right:-10px;
  }

  .card {
    border-radius: 16px;
  }
  
  .selectbutton {
    background-color: #f9d88a;
    margin-left:20px;
  }
  
  .sent-invites .card {
    background-color: #e4e4e4;
  }
  
  .sent-invites .card span {
    flex-grow: 1;
  }

  .sectionheader{
    color: #625555;
    font-size:18px;
    font-weight:700;
  }


  .cardname{
    font-weight:700;
    font-size:20px;
    margin-top: 1000px;

  }

  .cardagegender{
    color:#625555;
    margin-left:20px;
    font-size:14px;
  }

  .cardmatch{
    border-radius:12px;
    background-color: #27ca68;
    padding:3px 14px 3px 14px ;
    margin-left:20px;
    width: 63px;
    height:16px;
    font-weight: 500;
    font-size:12px;
  }

  .cardcommon{
    color:#625555;
    font-size:12px;
    margin-left: 5px;
  }

  .cardcommonbadge1, .cardcommonbadge2{
    width:170px;
    height:25px;
    background-color: #ed8713;
    border-radius:12px;
    color:white;
    padding:3px 14px 3px 14px ;
    margin-left:6px;
    font-size:12px;
  }
  
  .buttonicon{
    margin-left:-5px;
    margin-right:5px;
    height:10px;
  }

  .spacer {
    flex-grow: 1; /* Add flex-grow to create blank space */
  }

  .modalBtn{
    background-color: #f9d88a;
    border: none;
    align-items: center;
    cursor: pointer;
    padding:4px 2px 4px 2px;
    border-radius:12px;
    height:40px;
    width:100%;
    font-weight:700;
  }