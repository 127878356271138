.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background-color: #F9D88A;
  }

  .beelogo {
    height: 48px;
    min-width: 40px;
  }

  .renthivelogo {
    height: 34px;
    min-width:123px;
    margin-top:8px;
    margin-left:4px;
    margin-right: 32px;
  }

  .spacer {
    flex-grow: 1; /* Add flex-grow to create blank space */
  }