.navbar {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    background-color: #F9D88A;
  }
  
  .logo {
    display: flex;
    align-items: center;
  }

  .beelogo {
    height: 48px;
    min-width: 40px;
  }

  .renthivelogo {
    height: 34px;
    min-width:123px;
    margin-top:8px;
    margin-left:4px;
    margin-right: 32px;
  }
  
  .search-bar {
    margin-left: 8px;
  }

  .search-bar i {
    position: absolute;
    left: 255px;
    top: 35px;
    transform: translateY(-50%);
  }

  .search-bar input {
    border: none;
    border-radius: 16px;
    padding: 8px 16px;
    width: 300px;
    font-size: 16px;
  }

  .search-bar input[type="text"] {
    padding-left: 30px;
    text-indent: 10px;
  }

  .icons-container {
    display: flex;
  }
  
  .icons {
    margin-left: 20px;
    min-width: 36px;
    min-height: 36px;
  }
  
  .icons i {
    margin-left: 20px;
    font-size: 24px;
    
  }

  .spacer {
    flex-grow: 1; /* Add flex-grow to create blank space */
  }